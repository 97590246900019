<template>
  <div>
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div style="margin-bottom: 15px">
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">筛选</div>
        </template>
      </van-search>
    </div>

    <OrderList :searchParam="searchParam" ref="OrderList"></OrderList>
    <van-popup position="bottom" :style="{ height: '90%' }" v-model="orderInfoPopupShow">
      <van-field v-model="date" label="订单日期" placeholder="选择订单日期" @click="showCalendar = true"/>
          <van-calendar v-model="showCalendar" :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm"/>
      <div style="text-align: center;">
        <van-button style="margin-top: 20px; width: 80%" size="large" type="primary" @click="search">搜索</van-button>
      </div>

    </van-popup>
  </div>
</template>

<script>
import {
  Button,
  Calendar, Cascader, Cell, Field, Icon, NavBar, Popup, Search
} from 'vant';
import OrderList from "@/view/order/list";
import * as userAdminService from "@/api/admin/user";

export default {
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cascader.name]: Cascader,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    OrderList,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },

  data() {
    return {
      title: '用户账单',
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2025, 0, 31),
      searchParamValue: '',
      searchParam: {},
      username: '',
      address: '',
      orderInfoPopupShow: false,
      text: '',
      date: '',
      showCalendar: false,
    };
  },
  created() {
    if (this.$route.params && this.$route.params.userId) {
      userAdminService.getAccountUser(this.$route.params.userId).then(res => {
        if (res.status === 200) {
          this.title = res.data.nickName + '的账单'
        }
      })
      this.searchParam.userId = this.$route.params.userId;
    }
    if (this.$route.query && this.$route.query.queryDateType) {
      this.searchParam.queryDateType = this.$route.query.queryDateType
    }
  },
  methods: {
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.productName = null
      this.searchParam.orderDatetime = null
      this.$refs.OrderList.onRefresh();
    },
    onSearch() {
      this.searchParam.productName = this.searchParamValue;
      this.$refs.OrderList.onRefresh();
    },
    search() {
      this.searchParamValue = '多条件筛选'
      this.$refs.OrderList.onRefresh();
      this.orderInfoPopupShow = false
    },
    showPopup() {
      this.orderInfoPopupShow = true
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.date = this.formatDate(date);
      this.searchParam.orderDatetime = this.formatDate(date);
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>

<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in dataList" :key="item.id" :name="item.id">
          <div class="van-coupon">
            <div>
              <van-row>
                <van-col span="16">
                  <div style="height: 20px; padding-top: 5px;">
                    <span style="font-size: 12px; color: #323233; margin-left: 10px">{{ item.orderTime }}</span>
                  </div>
                </van-col>
                <van-col span="8">
                  <div style="height: 20px; padding-top: 5px; text-align: right;">
                    <van-icon v-if="item.isMerge === 0" name="delete-o" color="#ee0a24" size="25px" style="margin-right: 5px" @click="deleteOrder(item.id)"/>
                    <van-icon name="more-o" color="#1989fa" size="25px" style="margin-right: 5px" @click="toOrderInfo(item.id)"/>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div class="van-coupon__content" style="padding: 0" @click="getAccountOrderInfo(item.id)">
              <div class="van-coupon__head" style="max-width: 80px">
                <h3 class="van-coupon__amount" style="font-size: 16px">{{ item.totalPrice }}<span>元</span></h3>
                <p class="van-coupon__condition">数量：{{ item.totalNum }}</p>
              </div>
              <div class="van-coupon__body">
                <p class="van-coupon__name">{{ item.realName }}</p>
                <p class="van-coupon__valid">{{ item.userAddress }}</p>
                <div role="checkbox" class="van-checkbox van-coupon__corner" tabindex="0" aria-checked="false">
                  <div class="van-checkbox__icon van-checkbox__icon--round">
                      <van-tag  v-if="item.paid === 1" class="order-tag-margin-left" type="primary">付清</van-tag>
                      <van-tag  v-if="item.status === 1" class="order-tag-margin-left" type="success">结账</van-tag>
                      <van-tag v-if="item.status === 0 || item.status === -1" class="order-tag-margin-left" type="danger">欠账</van-tag>
                      <van-tag  v-if="item.isMerge === 1" class="order-tag-margin-left" type="warning">被合并</van-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="orderInfoPopupShow" @close="clearPopupData" :style="{height: '80%', width: '90%'}">
      <div v-for="item in orderInfos" :key="item.id" :name="item.id">
        <OrderProductInfo
          :product="item"
        ></OrderProductInfo>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Uploader,
  Popup,
  ContactCard,
  Tag,
  Image as VanImage,
  Card,
  Stepper,
  SubmitBar,
  Divider,
  Checkbox,
  DatetimePicker,
  Sticky,
  Tab,
  Tabs,
  Sidebar,
  SidebarItem, Loading, PullRefresh, ImagePreview, List, CheckboxGroup, Image, Notify, Empty, Dialog
  , CouponCell
} from 'vant';

import * as adminOrderService from "@/api/admin/order";
import OrderProductInfo from "@/components/order/orderProductInfo.vue"

export default {
  components: {
    OrderProductInfo,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell
  },
  name: "OrderList",
  props: ["searchParam"],
  data() {
    return {
      orderInfoPopupShow: false,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },

      orderInfos: []
    };
  },
  created() {
  },
  methods: {
    toOrderInfo(orderId) {
      this.$router.push({path: '/order/info/' + orderId})
    },
    deleteOrder(orderId) {
      Dialog.confirm({
        title: '确认删除此订单吗？',
        message: '删除此订，无法找回！',
      })
          .then(() => {
            adminOrderService.deleteAccountOrder(orderId).then(res => {
              if (res.status === 200) {
                Notify({type: 'success', message: '删除成功'});
                this.onRefresh()
              } else {
                Notify({type: 'danger', message: '删除失败'});
              }
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });

    },
    toOrderIndo() {
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfoCustom(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminOrderService.getAccountList(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log(this.pages)
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }

          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    clearPopupData() {
      this.orderInfos = []
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
.order-tag-margin-left {
  margin-left: 2px
}
</style>

<template>
  <div>
  <div style="background: #FFFFFF;">
    <div class="van-coupon__content" style="padding: 0;">
      <div class="van-coupon__head" style="max-width: 80px">
        <h3 class="van-coupon__amount" style="font-size: 16px">
          <van-image
            width="60"
            height="60"
            :src="product.image"
          />
        </h3>
        <p class="van-coupon__condition">{{ product.storeName }}</p>
      </div>
      <div class="van-coupon__body">
        <p class="van-coupon__name">计:{{ product.amount }}元</p>
        <div class="van-coupon__valid custom-font">
          共:{{ product.number }}{{product.unitName}}
        </div>
      </div>
    </div>
    <van-grid :column-num="4">
      <van-grid-item v-for="subSItem in product.attrInfos" :key="subSItem.uuid">
        <div class="purchase-sub-item-info">
          <span>{{ subSItem.sku }} <span class="purchase-sub-item-info-sl-span">{{ subSItem.price }}元</span></span>
          <div>
            <span>{{ subSItem.number }}{{ subSItem.unitName }}</span>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
  </div>
  </div>
</template>

<script>
import {CouponCell, CouponList, Grid, GridItem, Tag} from 'vant';
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      purchaseEditSkuObj: {},
    };
  },
  watch: {
    product: {
      handler(newVal) {
      },
      immediate: true,
    }
  },
  created() {
  },
  methods: {
  }
};
</script>

<style lang="less">
.custom-font {
  font-family: "PingFang SC";
}
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
.purchase-sub-item-info {
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
//.purchase-sub-item-info::before {
//  position: absolute;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  height: 2px;
//  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
//  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
//  background-size: 80px;
//  content: '';
//}
.purchase-sub-span {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 4px;
  color: #1989fa;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
}
.purchase-sub-item-info-sl-span {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
